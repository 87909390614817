document.querySelectorAll('.menu a').forEach((element, index) => {
    element.style.animationDelay = `0.${index + 2}s`;
});

AOS.init();

const items = document.getElementsByClassName('parent-content');

for (const parent of items) {
    parent.addEventListener('mouseenter', function() {
        const content = parent.querySelector('.content');
        content.style.maxHeight = content.scrollHeight + 'px';
        content.style.opacity = '1';
    });

    parent.addEventListener('mouseleave', function() {
        const content = parent.querySelector('.content');
        content.style.maxHeight = '0';
        content.style.opacity = '0';
    });
}